import React, { useEffect, useState } from 'react';
import bgImage from '../util/img/DSC_6049.png';
import FeedbackComponent from '../components/FeedbackComponent';
import ArticleTitle from '../components/ArticleTitle';
import StyledButton from '../components/StyledButton';

const FeedbackPage: React.FC = () => {
  const [feedbackData, setFeedbackData] = useState<any>({});

  useEffect(() => {
    fetch('/content-data/feedback/feedback.json')
      .then((response) => response.json())
      .then((data) => {
        setFeedbackData(data);
      })
      .catch((error) => console.error('Error loading feedback data:', error));
  }, []);

  const handleClick = () => {
    const url = 'https://forms.gle/Z9CCAmkb96KqyXES8';
    // Open the link in a new tab
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div
      className='top-0 pt-24 w-full min-h-screen bg-cover bg-center bg-fixed flex flex-col items-center justify-center'
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className='top-24 pt-8 lg:p-8 pl-[3%] pr-[3%] lg:pl-[7%] lg:pr-[7%] bg-custom-test w-full xl:w-2/3 text-center'>
        {/* <ArticleTitle smallHide text={feedbackData.header} /> */}

        <p className=' text-pretty text-lg text-black xl:pt-4 text-center'>
          {feedbackData.text1}
        </p>
      </div>

      {/* Scrollable Page Content */}
      <div className='relative z-0 px-6 bg-custom-test w-full xl:w-2/3'>
        <div className='pb-10 bg-transparent'>
          {/* Feedback Section */}

          {feedbackData?.feedbacks?.map(
            (
              feedback: {
                image: string;
                name: string;
                text: string;
                job: string;
              },
              index: React.Key | null | undefined
            ) => (
              <FeedbackComponent
                key={index}
                image={'/content-data/feedback/img/' + feedback.image}
                name={feedback.name}
                job={feedback.job}
                text={'/content-data/feedback/txt/' + feedback.text}
              />
            )
          )}
        </div>
      </div>
      <div className='flex lg:p-8 lg:pb-12 lg:pt-0 mb-24 pl-[3%] pr-[3%] lg:pl-[7%] lg:pr-[7%] bg-custom-test w-full xl:w-2/3 text-center justify-center'>
        <StyledButton
          handleClick={handleClick}
          text={'Chceš mi dát svojí zpětnou vazbu?'}
        />
      </div>
    </div>
  );
};

export default FeedbackPage;
