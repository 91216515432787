import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface SocialProps {
  image: IconProp;
  link: string;
  pad?: boolean;
}

const SocialImageLink: React.FC<SocialProps> = ({
  image,
  link,
  pad = false,
}) => {
  return (
    <a
      href={link}
      target='_blank'
      rel='noopener noreferrer'
      className=' transition-all duration-300 ease-in-out transform hover:scale-125 hover:shadow-inner'
    >
      <FontAwesomeIcon
        className={`h-[1.5rem] w-[1.5rem] ${pad ? '-ml-px' : ''}`}
        icon={image}
        size='xl'
      />
    </a>
  );
};

export default SocialImageLink;
