import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { AppProvider } from './context/AppContext';
import { HashRouter } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';

library.add(faInstagram, faFacebook);
ReactGA.initialize('G-J0NY7ECRBM');

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <AppProvider>
        <HashRouter basename='/'>
          <App />
        </HashRouter>
      </AppProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
