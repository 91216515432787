import React from "react";

interface StyledButtonProps {
  handleClick: () => void;
  text: string;
  additionalStyle?: string | null;
  styleTest?: boolean;
  loading?: boolean; // New loading prop
}

const buttonStyle =
  "bg-gradient-to-r from-custom-purple to-pink-500 text-white text-xs sm:text-base py-3 px-6 rounded-xl transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105 justify-end";
const buttonStyle2 =
  "text-white text-xs sm:text-base py-3 px-6 rounded-xl transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105 justify-end";

const StyledButton: React.FC<StyledButtonProps> = ({
  text,
  handleClick,
  additionalStyle = null,
  styleTest = false,
  loading = false, // Default to false
}) => {
  return (
    <button
      type="submit"
      onClick={handleClick}
      disabled={loading} // Disable button when loading
      className={`relative flex items-center justify-center text-base py-3 px-6 rounded-xl transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105 font-[500] ${
        loading
          ? "bg-gray-400 cursor-not-allowed"
          : "bg-[#f4c9d5] hover:bg-amber-900 text-black hover:text-white"
      }`}
    >
      {loading ? (
        <div className="flex space-x-2">
          <span className="h-2 w-2 bg-white rounded-full animate-pulse"></span>
          <span className="h-2 w-2 bg-white rounded-full animate-pulse delay-200"></span>
          <span className="h-2 w-2 bg-white rounded-full animate-pulse delay-400"></span>
        </div>
      ) : (
        text
      )}
    </button>
  );
};

export default StyledButton;
