import React, { useEffect, useRef, useState } from 'react';
import bgImage from '../util/img/DSC_6049.png';
import { useNavigate } from 'react-router-dom';
import img1 from '../util/img/sm_DSC_6013.jpg';
import img2 from '../util/img/sm_DSC_5969.jpg';
import img3 from '../util/img/sm_DSC_5958.jpg';
import img4 from '../util/img/sm_DSC_6008.jpg';

import StyledButton from '../components/StyledButton';
import ArticleTitle from '../components/ArticleTitle';
import Subscribe from '../components/Subscribe';

const AboutMe: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/reservation');
  };

  const [visibleImage1, setVisibleImage1] = useState(false);
  const [visibleImage2, setVisibleImage2] = useState(false);
  const [visibleImage3, setVisibleImage3] = useState(false);
  const [visibleImage4, setVisibleImage4] = useState(false);

  const img1Ref = useRef<HTMLImageElement>(null);
  const img2Ref = useRef<HTMLImageElement>(null);
  const img3Ref = useRef<HTMLImageElement>(null);
  const img4Ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Set visibility based on the image that is intersecting
            if (entry.target === img1Ref.current) {
              setVisibleImage1(true);
            } else if (entry.target === img2Ref.current) {
              setVisibleImage2(true);
            } else if (entry.target === img3Ref.current) {
              setVisibleImage3(true);
            } else if (entry.target === img4Ref.current) {
              setVisibleImage4(true);
            }
          }
        });
      },
      { threshold: 0.3 }
    );

    // Observe each image
    if (img1Ref.current) observer.observe(img1Ref.current);
    if (img2Ref.current) observer.observe(img2Ref.current);
    if (img3Ref.current) observer.observe(img3Ref.current);
    if (img4Ref.current) observer.observe(img4Ref.current);

    return () => {
      // Unobserve each image
      if (img1Ref.current) observer.unobserve(img1Ref.current);
      if (img2Ref.current) observer.unobserve(img2Ref.current);
      if (img3Ref.current) observer.unobserve(img3Ref.current);
      if (img4Ref.current) observer.unobserve(img4Ref.current);
    };
  }, []);

  return (
    <>
      <div
        className='flex flex-col top-0 pt-24 w-full min-h-screen bg-cover bg-center bg-fixed justify-center'
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        {/* Uvod */}
        {/* Kdo jsem */}
        <section className='p-8 lg:pl-[25%] lg:pr-[25%] lg:pt-12 pb-12 bg-white font-[500] text-center lg:text-left'>
          <h2 className="text-2xl font-[600] font-['PetitFormalScript-Regular'] pb-8 mt-4">
            "Pomáhám překonávat pocit, že se nikdy nic nezmění, aby každý mohl
            najít v duši, mysli a těle klid."
          </h2>
          <div className='flex flex-col xl:flex-row w-full mt-4 items-center lg:items-start'>
            <div className='flex-col xl:pr-12 lg:pt-12'>
              <ArticleTitle text='Veronika Hývlová' subtitle='Theta healerka' />

              <p className='pt-4 pb-4 font-[500] leading-[2]'>
                Každý den děkuju za možnost setkat se s metodou Theta healing a
                moct tak uzdravit svoje tělo. Nadále na sobě pracuji a život mi
                to neustále vrací! Dává mi příležitost kreativně tvořit a žít
                tak svoje sny. Naučila jsem se nevzdávat se a získala tak
                vnitřní svobodu.
              </p>
              <p className='font-[500] leading-[3] pb-4'>
                Ale vždycky to tak nebylo.
              </p>
            </div>
            <div ref={img1Ref}>
              <img
                // ref={img1Ref}
                src={img1}
                alt='Logo'
                className={`scale-x-[-1] h-full max-w-80 mb-8 xl:mb-0  transform transition-all duration-4000 ease-in opacity-0 -translate-x-full ${
                  visibleImage1 ? 'opacity-100 translate-x-0' : ''
                }`}
                onClick={handleClick}
              />
            </div>
          </div>
        </section>
        <section className='p-8 lg:pl-[25%] lg:pr-[25%] pt-12 pb-12 bg-custom-test font-[500]'>
          <div className='white'>
            <ArticleTitle text='Můj příběh aneb jak se stalo, že nabízím Theta healing sezení' />
            <p className='pt-4 pb-4 leading-[2]'>
              Od 12 let jsem měla problémy se zády. Bolela mě krční páteř a
              lopatky, bylo to, jako kdybych v zádech měla dva kameny. Chodila
              jsem na rehabilitace a masáže, ale nic z toho mi dlouhodobě
              nepomáhalo.
            </p>
            <div
              ref={img2Ref}
              className='flex flex-col xl:flex-row w-full items-center'
            >
              <img
                src={img2}
                alt='Logo'
                className={`h-full max-w-60  mb-8 xl:mb-0 transform transition-all duration-[5000] ease-in-out opacity-0 -translate-x-full ${
                  visibleImage2 ? 'translate-x-0 opacity-100' : ''
                }`}
                onClick={handleClick}
              />
              <div className='flex-col xl:pl-12 mt-4 leading-[2]'>
                <p className='pt-4 pb-4 font-[600] text-xl'>Tlak společnosti</p>
                <p className='pb-3'>
                  V následujících letech s přibývajícími stresy ze školy, vztahů
                  a tlaku vrstevníků na to, co je běžné fyzicky zvládnout,
                  přišlo zhoršení bolestí. Mimo to přibývaly další v bedrech a
                  chodidlech.
                </p>
                <p className='pb-3'>
                  Převzala jsem názor svého okolí - že jen doktoři mi můžou
                  pomoct. Prošla jsem rukama řadou lékařů a fyzioterapeutů, ale
                  nevěděli si se mnou rady.
                </p>
                <p className='pb-3'>
                  A protože jsem se nemohla sama uzdravit a vymykala se tak
                  lékařskému “normálu”, myslela jsem si, že je se mnou něco
                  špatně. Snahu se vyléčit jsem tehdy úplně vzdala a naučila
                  jsem se s tím žít.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='p-8 lg:pl-[25%] lg:pr-[25%] pt-6 pb-12 bg-custom-test font-[500]'>
          <div className='relative flex-col w-full leading-[2]'>
            <p className='pt-4 pb-4 font-[600] uppercase text-xl'>
              Nastal zlom
            </p>
            <p className='pt-4'>
              Když jsem se osamostatnila, začala jsem měnit pohled na to, že
              musím věřit pouze lékařům. Do mého života přicházeli lidi co
              věřili v něco víc a také v to, že je všechno možné.
            </p>
            <p className='pt-4'>
              Současně jsem ale na sebe nechala navalovat práci, která byla pro
              jednoho člověka nezvladatelná. Snažila jsem se to všechno unést na
              svých zádech ve snaze, abych byla dost dobrá.
            </p>
            <p className='pt-4'>
              To vše se změnilo v moment, kdy jsem upadla na schodech a
              přivodila si tak zranění, které mě přinutilo se zastavit.
              Uvědomila jsem si, že jsem měla problém s nastavením hranic a
              sebehodnotou. Postupně jsem začala pracovat na svém sebevědomí a
              to vedlo ke změně práce. Opět mě ale dohnal tlak z množství práce,
              nebylo pro mě možné bezbolestně sedět a skončila jsem na
              neschopence. Tehdy přišla panika, že nejsem schopná pracovat kvůli
              svému tělu.
            </p>
            <p className='pt-4'>
              Přesto prvotní impuls mozku byl - “To musím dát! Musím se vrátit!”
            </p>
          </div>
        </section>
        <section className='p-8 lg:pl-[25%] lg:pr-[25%] pt-12 pb-12 bg-white font-[500]'>
          <div className='bg-transparent leading-[2] '>
            <p className='pt-4 pb-4 font-[600] text-xl'>Tělo nás učí</p>
            <p>
              Tehdy mi už konečně došlo, že moje bolesti byly spojené s
              psychikou. Sebrala jsem odvahu a začala jsem chodit na terapie,
              naplno meditovat. Učila jsem se jen tak být, hledala jsem cestu
              sama k sobě. Začala jsem cítit úlevu a učit se vděčnosti - záda mi
              celou tu dobu dávala zprávu, já ji jen neposlouchala.
            </p>
            <div
              ref={img3Ref}
              className='flex flex-col xl:flex-row w-full items-center pt-4 lg:pt-0'
            >
              <img
                src={img3}
                alt='Logo'
                className={`h-full max-w-80  transform transition-all duration-[5000] ease-in-out opacity-0 -translate-x-full ${
                  visibleImage3 ? 'translate-x-0 opacity-100' : ''
                }`}
                onClick={handleClick}
              />
              <div className='flex-col xl:pr-12 lg:pl-8 pt-4 mt-4'>
                <p className=''>
                  Postupně jsem změnila přesvědčení, že moje tělo není dost
                  silné na to, abych mohla pracovat{' '}
                  <b>
                    – laskavostí a soucitem k sobě samé jsem k tělu opět nalezla
                    důvěru.
                  </b>
                </p>
                <p className='pt-4'>
                  Došla jsem k závěru, že utíkat do jiné práce nemá smysl. Proto
                  jsem zůstala s novým přístupem a naučila se říkat ne, když je
                  to potřeba. Postupně jsem ale se seberozvojem přišla na to, co
                  mě doopravdy naplňuje a začala se tím směrem vydávat.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='p-8 lg:pl-[25%] lg:pr-[25%] lg:pt-16 pb-16 bg-white  font-[500] leading-[2]'>
          <p className='lg:pt-4 pb-4 font-[600] text-xl uppercase'>
            Uvěřila jsem, že existuje metoda, co mě vyléčí
          </p>
          <p className='pt-4'>
            Nevzdala jsem představu, že se můžu plně vyléčit a zkoušela různé
            metody s myšlenkou, že jsem pouze nenašla tu pravou. Shodou
            okolností jsem se dostala k <b>Theta healingu</b>, který mi přes
            moje nulové očekávání úspěchu{' '}
            <b>výrazně pomohl už po prvním sezení!</b>
          </p>
          <p className='pt-4'>
            Najednou jsem zvládla cviky, které do té doby byly problematické,
            mohla jsem znovu začít stanovat a chodit na tůry. Okamžitě jsem
            cítila potřebu metodou Theta healing pomáhat všem lidem, kteří touží
            po změně jako jsem toužila já.
          </p>
        </section>
        <section className='p-8 lg:pl-[25%] lg:pr-[25%] pt-12 bg-custom-test pb-20 font-[500]'>
          <div
            ref={img4Ref}
            className='flex flex-col xl:flex-row w-full items-center lg:items-start leading-[2] '
          >
            <img
              src={img4}
              alt='Logo'
              className={`h-auto max-w-72 transform transition-all duration-[5000] ease-in-out opacity-0 -translate-x-full ${
                visibleImage4 ? 'translate-x-0 opacity-100' : ''
              }`}
              onClick={handleClick}
            />
            <div className='flex-col xl:pl-12 pt-10 items-center'>
              <p className='hidden sm:block pt-4 pb-4 font-[600] text-xl uppercase'>
                {'A tak vznikla Cesta světla'}
              </p>

              <p className='flex flex-col sm:hidden pt-4 pb-4 font-semibold text-xl uppercase'>
                <span className='inline '>A tak vznikla</span>
                <span className='inline '>Cesta světla</span>
              </p>

              <p>
                Prošla jsem několik výcviků metody Theta healing a teď pomáhám
                lidem projít obdobími, kdy cítí bezmoc nebo kdy jim svítí slunce
                ale věří, že to může být ještě lepší.
              </p>
              <p className='pt-6 font-[600]'>
                Cesta Světla je tu pro všechny, co chtějí ušetřit čas a místo
                tápání, kterým jsem prošla já, se rovnou vrhnout na řešení.
              </p>
              <p className='pt-4 pb-10 '>
                Ať už čelíš jakékoliv výzvě, věřím, že naše spolupráce
                tě posune vpřed.
              </p>
              <p className='flex justify-center lg:justify-start'>
                <StyledButton
                  handleClick={handleClick}
                  additionalStyle='mt-5'
                  text='Rezervace schůzky'
                />
              </p>
            </div>
          </div>
        </section>
        <Subscribe />
      </div>
    </>
  );
};

export default AboutMe;
