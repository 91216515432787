import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ArticleTitle from './ArticleTitle';
import bgImage from '../util/img/DSC_6049.png';
import mammoth from 'mammoth';
import AuthorSign from './AuthorSign';
import QAContent from './articles/QAContent';
import StyledButton from './StyledButton';
import ReactDOM from 'react-dom';
import NextArticles from './NextArticles';
import Subscribe from './Subscribe';
import img2 from '../util/img/sm_DSC_6032.jpg';
import SEO from './SEO';

const Article: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [article, setArticle] = useState<any>({});
  const [textData, setTextData] = useState<string>('');
  const contentRef = useRef(null);
  const navigate = useNavigate();

  const getSEO = (idStr: string) => {
    if (idStr === 'qa') {
      return idStr;
    }
    if (idStr === 'theta') {
      return idStr;
    }
    if (idStr === 'stvoritel') {
      return idStr;
    }
    if (idStr === 'prubeh') {
      return idStr;
    } else {
      return 'articles';
    }
  };
  const handleClick = () => {
    navigate('/reservation');
  };
  const handleClick2 = () => {
    navigate('/about');
  };

  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash, textData]);

  useEffect(() => {
    fetch('/content-data/articles/articles.json')
      .then((response) => response.json())
      .then((data) => {
        const article = data.articles.find(
          (article: { id: string }) => article.id === id
        );
        setArticle(article);
      })
      .catch((error) => console.error('Error loading feedback data:', error));
  }, [id]);

  useEffect(() => {
    fetch('/content-data/articles/txt/' + article.content)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        // Convert the DOCX binary data to HTML
        return mammoth.convertToHtml(
          { arrayBuffer },
          {
            styleMap: [
              "p[style-name='Heading 1'] => h1:fresh",
              "p[style-name='Heading 2'] => h2:fresh",
              "p[style-name='Normal'] => p:fresh", // Handle normal paragraphs
              "p[style-name='No Spacing'] => p:fresh", // Handle paragraphs without spacing
              'b => strong', // Convert bold text to strong
              'i => em', // Convert italic text to em
              'u => u', // Convert underlined text to <u>
              "p[style-name='Hyperlink'] => a", // Handle hyperlinks
              'r => br', // Convert hard line breaks
              'ul => ul.mammoth-list', // Add class to <ul> for custom styling
              'li => li.mammoth-item', // Add class to <li> for square styling
            ],
          }
        );
      })
      .then((result) => {
        let modifiedHtml = result.value; // Extracted HTML
        console.log(modifiedHtml);
        if (id === 'prubeh') {
          // Inject a placeholder for the button
          modifiedHtml = modifiedHtml.replace(
            '(odkaz na rezervuj si sezení se mnou)',
            "<div id='button-placeholder'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(foto1)',
            "<div id='img1-placeholder'></div>" // Placeholder where the button will go
          );
        }
        if (id === 'theta') {
          // Inject a placeholder for the button
          modifiedHtml = modifiedHtml.replace(
            '(Více o mém příběhu zde)',
            "<div id='button-placeholder2'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(odkaz na rezervuj si sezení se mnou)',
            "<div id='button-placeholder'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(foto1)',
            "<div id='img1-placeholder'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replaceAll(
            '<ul>',
            '<ul class="mammoth-list">' // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replaceAll(
            '<li>',
            '<li class="mammoth-item">' // Placeholder where the button will go
          );
        }
        setTextData(modifiedHtml); // Set HTML into state
      })
      .catch((error) => console.error('Error loading document:', error));
  }, [article, id]);

  useEffect(() => {
    // After the HTML is rendered, find the placeholder and inject the button
    if ((id === 'theta' || id === 'prubeh') && contentRef.current) {
      const placeholder = document.getElementById('button-placeholder');
      const placeholder1 = document.getElementById('img1-placeholder');
      const placeholder2 = document.getElementById('button-placeholder2');
      if (placeholder) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <StyledButton
            handleClick={handleClick}
            text='Rezervuj si sezení se mnou'
          />,
          placeholder
        );
      }
      if (placeholder2) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <StyledButton handleClick={handleClick2} text='Víc o mém příběhu' />,
          placeholder2
        );
      }
      if (placeholder1) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <div className='flex h-64 overflow-hidden lg:h-80 items-center justify-center w-full'>
            <img
              src={img2}
              alt='Logo'
              className='w-full lg:w-auto h-60 lg:h-80 object-cover '
            />
          </div>,
          placeholder1
        );
      }
    }
  }, [textData]);

  return (
    <>
      <SEO page={getSEO(id ?? 'articles')} />
      <div
        className='top-0 pt-24 w-full min-h-screen bg-cover bg-center bg-fixed flex flex-col items-center'
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <section className='p-6 lg:p-16 lg:pl-[20%] lg:pr-[20%] bg-white items-center justify-center'>
          <p className='text-center pt-6 pb-6'>
            <ArticleTitle text={article.title} />
          </p>
          {id === 'qa' && <QAContent />}
          {id !== 'qa' && (
            <div>
              {' '}
              <div
                ref={contentRef}
                onLoadedData={() => console.log('loaded')}
                dangerouslySetInnerHTML={{ __html: textData }} // Insert HTML into the component
              />
            </div>
          )}
        </section>
        <NextArticles />
        <AuthorSign />
        <Subscribe />
      </div>
    </>
  );
};

export default Article;
