import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { faHome } from "@fortawesome/free-solid-svg-icons";

interface NavigationLinkProps {
  dark?: boolean;
  navTo: string;
  navString: string;
  navStringCall?: (linkString: string) => void;
  hasIcon?: boolean;
}

const NavigationLink: React.FC<NavigationLinkProps> = ({
  navTo,
  navString,
  navStringCall,
  dark = false,
  hasIcon = false,
}) => {
  const styleBase = dark ? "text-amber-900" : " text-white ";
  const shadowColor = dark ? "shadow-amber-900 shadow-ms2" : "shadow-ms";
  const shadowColorHover = dark ? "shadow-amber-900 hover:shadow-ms2" : "hover:shadow-ms";
  const location = useLocation();
  const getLinkClasses = (path: string) =>
    location.pathname === path
      ? `${styleBase} ${shadowColor} flex text-base lg:text-lg font-black py-2 px-5 font-['MyCustomFont2']`
      : `${styleBase} ${shadowColorHover} text-base lg:text-lg font-black py-2 px-5 font-['MyCustomFont2'] transition-all duration-300 ease-in-out transform`;

  return (
    <li
      className="flex items-end justify-end transition-all duration-300 ease-in-out transform hover:scale-105"
      onClick={() => navStringCall && navStringCall(navString)}
    >
      <NavLink to={navTo} className={getLinkClasses(navTo)}>
        <span className="flex items-end">
          {hasIcon && (
            <FontAwesomeIcon
              icon={faHome}
              size="lg"
              className={`mb-1 ${navString ? "relative bottom-2" : "bottom-2"}`} // Keep consistent alignment
            />
          )}
          {navString && <span className="flex items-end">{navString}</span>}
        </span>
      </NavLink>
    </li>
  );
};

export default NavigationLink;
//
