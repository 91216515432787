import React, { useEffect, useRef, useState } from "react";
import emailjs from "emailjs-com";
import StyledButton from "./StyledButton";

interface ContactFormProps {
  show?: boolean;
}

// Define the structure of the form data
interface FormData {
  name: string;
  email: string;
  subject: string;
  message: string;
}

// Define the structure of the MailerLite response
interface MailerLiteResponse {
  success: boolean;
  message?: string;
}

const ContactForm: React.FC<ContactFormProps> = ({ show }) => {
  const form = useRef<HTMLFormElement | null>(null);
  const [status, setStatus] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [contactData, setContactData] = useState<any>([]);

  const labelStyle = "block text-xs sm:text-base font-bold text-gray-700";
  const inputStyle =
    "mt-1 p-2 block w-full border border-gray-300 rounded-md text-base";

  const sendEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true); // Start loading state

    if (form.current) {
      try {
        // Send the first email (to the user)
        await emailjs.sendForm(
          `${process.env.REACT_APP_EMAILJS_SERVICE}`,
          `${process.env.REACT_APP_EMAILJS_CLIENT_TEMPLATE}`,
          form.current,
          `${process.env.REACT_APP_EMAILJS_API_KEY}`
        );

        // Send the second email (to yourself)
        await emailjs.sendForm(
          `${process.env.REACT_APP_EMAILJS_SERVICE}`,
          `${process.env.REACT_APP_EMAILJS_TO_ME_TEMPLATE}`,
          form.current,
          `${process.env.REACT_APP_EMAILJS_API_KEY}`
        );

        setStatus("Tvoje zpráva byla úspěšně odeslána.");

        // After sending the emails, send the form data to MailerLite
        const formData = {
          name: form.current?.user_name.value,
          email: form.current?.user_email.value,
          subject: form.current?.user_subject.value,
          message: form.current?.user_message.value,
        };
        const result = await sendFormDataToMailerLite(formData);
        form.current.reset();
        // Handle MailerLite success or error messages
        if (result.success) {
          setStatus("Tvoje zpráva byla úspěšně odeslána a přidána do skupiny.");
        } else {
          setStatus(result.message || "Chyba při přidání do skupiny.");
        }
      } catch (error) {
        console.error(error);
        setStatus("Failed to send email.");
      } finally {
        setLoading(false); // End loading state
      }
    }
  };

  // Function to send form data to MailerLite API and add user to a group
  const sendFormDataToMailerLite = async (
    data: FormData
  ): Promise<MailerLiteResponse> => {
    const apiURL = "https://connect.mailerlite.com/api/subscribers";
    const apiKey = `Bearer ${process.env.REACT_APP_MAILERLITE_API_KEY}`;
    const groupId = `${process.env.REACT_APP_MAILERLITE_MESSAGES_GROUPID}`; // Replace with the group ID

    try {
      const response = await fetch(`${apiURL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // prettier-ignore
          "Authorization": apiKey,
        },
        body: JSON.stringify({
          email: data.email,
          groups: [groupId],
          fields: {
            name: data.name,
            subject: data.subject,
            message: data.message,
          },
        }),
      });

      if (response.ok) {
        return { success: true };
      } else {
        const errorData = await response.json();
        return { success: false, message: errorData.error.message };
      }
    } catch (error) {
      console.error("Error sending form data to MailerLite:", error);
      return {
        success: false,
        message: "Network error. Please try again later.",
      };
    }
  };

  useEffect(() => {
    fetch("/content-data/contact/contact.json")
      .then((response) => response.json())
      .then((data) => setContactData(data))
      .catch((error) => console.error("Error loading feedback data:", error));
  }, []);

  return (
    <div className="flex flex-col w-full p-4 text-xl">
      <form
        ref={form}
        onSubmit={sendEmail}
        id="messageForm"
        className="space-y-4 text-xl"
      >
        <div className="hidden">
          <input
            type="text"
            name="my_email"
            placeholder="Email"
            defaultValue={"info-cestasvetla@seznam.cz"}
            className={inputStyle}
            required
          />
        </div>
        <div>
          <input
            type="text"
            name="user_name"
            placeholder="Jméno"
            className={inputStyle}
            required
          />
        </div>
        <div>
          <input
            type="email"
            name="user_email"
            placeholder="Tvůj email"
            className={inputStyle}
            required
          />
        </div>
        <div>
          <input
            name="user_subject"
            placeholder="Předmět"
            className={inputStyle}
            required
          />
        </div>
        <div>
          <label className={labelStyle}>{"Tvoje zpráva"}</label>
          <textarea
            name="user_message"
            className={inputStyle}
            rows={5}
            required
          ></textarea>
        </div>
        <div className="flex w-full justify-center sm:justify-end">
          {!status && (
            <StyledButton
              text={loading ? "Odesílání..." : contactData.formSendButton}
              handleClick={() => {}}
            />
          )}
          {status && (
            <p className="mt-2 mr-8 pt-4 pb-4 font-[600] leading-[2]">
              {status}
            </p>
          )}
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
