import React from "react";
import ArticleTitle from "./ArticleTitle";
import profile from "../util/img/small/profile.jpg";
//sm_DSC_6013

const AuthorSign: React.FC = ({}) => {
  return (
    <section className="p-8 w-full bg-white">
      <div className="flex flex-row items-center lg:pl-[20%] lg:pr-[20%]">
        <img
          src={profile}
          alt="xxx"
          className="w-24 h-24 rounded-full aspect-square object-cover shadow-lg shadow-amber-900"
        />
        <div className="flex flex-col pl-8">
          <ArticleTitle
            text={"Veronika Hývlová"}
            subtitle="Průvodkyně metody THETA HEALING"
          />
          <p className="hidden lg:block font-[500] ">
            "Pomáhám překonávat pocit, že se nikdy nic nezmění, aby každý mohl
            najít v duši, mysli a těle klid."
          </p>
        </div>
      </div>
      <p className="lg:hidden font-[500] ">
        "Pomáhám překonávat pocit, že se nikdy nic nezmění, aby každý mohl najít
        v duši, mysli a těle klid."
      </p>
    </section>
  );
};

export default AuthorSign;
