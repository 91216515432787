import React from 'react';
import ArticleTitle from './../ArticleTitle';
import StyledButton from '../StyledButton';
import { useNavigate } from 'react-router-dom';

const QAContent: React.FC = ({}) => {
  const navigate = useNavigate();
  const handleClickTheta = () => {
    navigate('/articles/theta');
  };
  const handleClickArticles = () => {
    navigate('/articles/prubeh');
  };
  const handleClickReservation = () => {
    navigate('/reservation');
  };
  const handleClickContact = () => {
    navigate('/contact');
  };

  const csq = (
    <span className='text-black text-lg font-[600] pt-[10px] lg:pt-0 leading-[1.2] lg:leading-[2.5] pr-2'>
      ■
    </span>
  );

  return (
    <section className='w-full bg-white'>
      <div className='flex flex-row items-center'>
        <div className='flex flex-col'>
          <div className='flex flex-row pt-1'>
            {csq}
            <p className='text-lg font-[600] pt-2 pb-3 '>
              Probíhá sezení online?
            </p>
          </div>
          <p>Ano, vše probíhá z pohodlí tvého domova.</p>

          <div className='flex flex-row pt-1'>
            {csq}
            <p className='text-lg font-[600] pt-2 pb-3 '>
              Jak dlouho trvá sezení?
            </p>
          </div>
          <p>60 nebo 90 minut, záleží na tobě. </p>

          <div className='flex flex-row pt-1'>
            {csq}
            <p className='text-lg font-[600] pt-2 pb-3 '>
              Jaké problémy mohu metodou řešit?
            </p>
          </div>
          <p>
            Psychické (Například vztahy, finance, kariéru) i fyzické (bolesti,
            onemocnění).
          </p>

          <div className='flex flex-row pt-1'>
            {csq}
            <p className='text-lg font-[600] pt-2 pb-3 '>
              Jak rychle uvidím výsledky?
            </p>
          </div>
          <p>
            Je možné, že už po prvním sezení, nicméně je důležité mít trpělivost
            a otevřenost vůči procesu.
          </p>

          <div className='flex flex-row pt-1'>
            {csq}
            <p className='text-lg font-[600] pt-2 pb-3 '>
              Je Theta healing nepříjemný?
            </p>
          </div>
          <p>
            Právě naopak většina klientů zažívá pocity uvolnění a osvobození už
            během sezení.
          </p>

          <div className='flex flex-row pt-1'>
            {csq}
            <p className='text-lg font-[600] pt-2 pb-3 '>
              Potřebuju nějaké předchozí zkušenosti?
            </p>
          </div>
          <p>
            Není potřeba mít žádné předchozí zkušenosti. Metoda je pro každého,
            kdo chce zlepšit svůj život.
          </p>

          <div className='flex flex-row pt-1'>
            {csq}
            <p className='text-lg font-[600] pt-2 pb-3 '>
              Jak se připravit na sezení?
            </p>
          </div>
          <p>
            Není potřeba žádná příprava, stačí mít představu co chceš řešit za
            téma.
          </p>

          <div className='flex flex-row pt-1'>
            {csq}
            <p className='text-lg font-[600] pt-2 pb-3 '>
              Musím mít víru v Boha?
            </p>
          </div>
          <p>
            Ne, stačí otevřenost vůči myšlence v něco víc, co nás přesahuje.
          </p>

          <div className='flex flex-row pt-1'>
            {csq}
            <p className='text-lg font-[600] pt-2 pb-3 '>
              Můžu na sezení, <span className='whitespace-nowrap'> i když</span>
              <span className='whitespace-nowrap'> beru léky?</span>
            </p>
          </div>
          <p>
            Ano, není tam omezení. Nenahrazuje však lékařskou péči, pouze
            doplňuje.
          </p>

          <div className='flex flex-row pt-1'>
            {csq}
            <p className='text-lg font-[600] pt-2 pb-3 '>
              Můžu na sezení{' '}
              <span className='whitespace-nowrap'>v těhotenství?</span>
            </p>
          </div>
          <p>Ano, není tam omezení.</p>

          <div className='flex flex-row pt-1'>
            {csq}
            <p className='text-lg font-[600] pt-2 pb-3 '>
              Co je Theta Healing?
            </p>
          </div>
          <p>
            Theta Healing je technika energetického léčení, probíhající v
            meditaci.
          </p>

          {/* <p>Více o Theta healingu ZDE </p> */}
          <p className='flex pt-4 justify-center lg:justify-start'>
            <StyledButton
              handleClick={handleClickTheta}
              additionalStyle='mt-5'
              text='Více o Theta healingu'
            />
          </p>
          <div className='flex flex-row pt-1'>
            {csq}
            <p className='text-lg font-[600] pt-2 pb-3 '>Jak probíhá sezení?</p>
          </div>
          <p>V bezpodmínečné lásce a lehkosti, vše se souhlasem klienta. </p>
          {/* <p>Více viz odkaz na článek jak probíhá</p> */}
          <p className='flex pt-4 justify-center lg:justify-start'>
            <StyledButton
              handleClick={handleClickArticles}
              text='Více o průběhu'
            />
          </p>

          <p className='flex pt-12 justify-center lg:justify-start space-x-0 lg:space-x-4'>
            <StyledButton
              handleClick={handleClickReservation}
              text='Rezervace schůzky'
            />
          </p>
          <p className='pt-8 text-lg text-center lg:text-start text-[600]'>
            Máš další otázky?
          </p>

          <p className='flex pt-4 justify-center lg:justify-start space-x-0 lg:space-x-4'>
            <StyledButton
              handleClick={handleClickContact}
              text='Kontaktní formulář'
            />
          </p>
        </div>
      </div>
    </section>
  );
};

export default QAContent;
