import React from 'react';

interface ArticleTitleProps {
  text: string;
  subtitle?: string;
  line?: boolean;
  light?: boolean;
  smallHide?: boolean;
  uc?: boolean;
}

const ArticleTitle: React.FC<ArticleTitleProps> = ({
  text,
  subtitle,
  line = false,
  light = false,
  smallHide = false,
  uc = true,
}) => {
  return (
    <>
      <h1
        className={`text-2xl ${
          light ? 'text-white' : 'text-secondary-button-color'
        } ${smallHide ? 'hidden lg:block' : ''} ${
          uc ? 'uppercase' : ''
        } pb-2 font-['MyCustomFont2']`}
      >
        {text}
      </h1>
      {line && !smallHide && (
        <div className='h-px w-full bg-secondary-button-color mb-5' />
      )}
      {subtitle && (
        <h2
          className={`text-base ${
            light ? 'text-white' : 'text-secondary-button-color'
          } uppercase -mt-3 pb-2 pt-2 font-['MyCustomFont2']`}
        >
          {subtitle}
        </h2>
      )}
    </>
  );
};

export default ArticleTitle;
