import bgImage from '../util/img/DSC_6049.png';
import AuthorSign from '../components/AuthorSign';
import ArticleTitle from '../components/ArticleTitle';

const ContractPage: React.FC = () => {
  return (
    <div
      className='top-0 pt-24 w-full min-h-screen bg-cover bg-center bg-fixed flex flex-col justify-center'
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <section className={'p-8 pb-20 bg-custom-test my-auto'}>
        <div className='pl-[20%] pr-[20%] flex flex-row items-center'>
          <div
            className=' 
           flex-col'
          >
            <ArticleTitle text='Obchodní podmínky' />
            <section id='Obsah'>
              <div className='flex flex-col pl-4'>
                <p className='text-lg font-[600]'>1. Obecná ustanovení</p>
                <p className='text-lg font-[600]'>
                  {' '}
                  2. Objednávka a Uzavření kupní smlouvy
                </p>
                <p className='text-lg font-[600]'>3. Cena a Způsob úhrady </p>
                <p className='text-lg font-[600]'>4. Dodací podmínky</p>
                <p className='text-lg font-[600]'>
                  5. Zabezpečení a Ochrana autorských práv
                </p>
                <p className='text-lg font-[600]'>6. Odstoupení od smlouvy</p>

                <p className='text-lg font-[600]'>
                  7. Práva z vadného plnění, reklamace{' '}
                </p>
                <p className='text-lg font-[600]'>8. Garance vrácení peněz</p>
                <p className='text-lg font-[600]'>9. Vyloučení odpovědnosti</p>
                <p className='text-lg font-[600]'>10. Závěrečná ustanovení</p>
              </div>
            </section>

            <section id='part1' className='pt-8'>
              <ArticleTitle text='1. Obecná ustanovení' />
              <p className='pb-3'>
                Tyto všeobecné obchodní podmínky (dále jen "Podmínky") platí pro
                prodej produktů a služeb prodávajícího, kterým je fyzická osoba
                podnikatel
              </p>
              <p>Ing. Veronika Hývlová</p>
              <p className='pb-3'>IČ: 22166432</p>
              <p className='pb-3'>
                kupujícímu prostřednictvím prostředků komunikace na dálku přes
                rozhraní webových stránek prodávajícího.
              </p>
              <p className='pb-3'>
                Podmínky blíže vymezují a upřesňují práva a povinnosti
                prodávajícího (dále také pořadatele) a kupujícího (dále také
                „zákazník“ či „účastník“).
              </p>
              <p className='pb-3'>
                Ustanovení obchodních podmínek jsou nedílnou součástí kupní
                smlouvy a jsou zobrazeny na webových stránkách prodávajícího
                cestasvetla.eu .
              </p>
              <p className='pb-3'>
                Je-li smluvní stranou spotřebitel (jiná osoba, než podnikatel,
                který činí objednávku v rámci své podnikatelské činnosti), řídí
                se vztahy neupravené obchodními podmínkami také občanským
                zákoníkem (č. 89/2012 Sb.) a zákonem o ochraně spotřebitele (č.
                634/1992 Sb.).
              </p>
            </section>

            <section id='part2' className='pt-8'>
              {' '}
              <ArticleTitle text=' 2. Objednávka a Uzavření kupní smlouvy' />
              <p className='pb-3'>
                <b>2.1</b> Označení produktu, popis jeho hlavních vlastností a
                cena je uvedeno na webových stránkách prodávajícího
                cestasvetla.eu. Cena je dále vždy uvedena i na objednávkovém
                formuláři. Nabídka prodeje zůstává v platnosti po dobu, kdy je
                zobrazována na webovém rozhraní.
              </p>
              <p className='pb-3'>
                <b>2.2</b> Prodejní formulář obsahuje vždy informace o
                zákazníkovi, objednávaném produktu nebo službě, ceně včetně
                uvedení poplatků, způsobu úhrady kupní ceny, údaje o požadovaném
                způsobu doručení a informace o nákladech spojených s dodáním
                produktu či poskytnuté služby. U on-line produktů náklady na
                dodání účtovány nejsou. Náklady vzniklé zákazníkovi při použití
                komunikačních prostředků na dálku v souvislosti s uzavřením
                kupní smlouvy (náklady na internetové připojení, náklady na
                telefonní hovory) si hradí zákazník sám.
              </p>
              <p className='pb-3'>
                <b>2.3</b> Smluvní vztah mezi prodávajícím a zákazníkem vzniká
                odesláním závazné objednávky zákazníkem prostřednictvím
                objednávkových formulářů na výše uvedené webové stránce. Od
                tohoto momentu vznikají mezi ním a prodávajícím vzájemná práva a
                povinnosti, jež jsou vymezeny kupní smlouvou a těmito obchodními
                podmínkami. Podáním objednávky zákazník stvrzuje, že se seznámil
                s těmito obchodními podmínkami a že s nimi souhlasí. Podmínkou
                platné elektronické objednávky je vyplnění všech předepsaných
                údajů a náležitostí uvedených v prodejním formuláři. Údaje
                uvedené v objednávce jsou prodávajícím považovány za správné.
              </p>
              <p className='pb-3'>
                <b> 2.4</b> Zákazník souhlasí s použitím prostředků komunikace
                na dálku při uzavírání smlouvy.
              </p>
              <p className='pb-3'>
                <b> 2.5</b> Prodávající je povinen poskytnout či dodat produkt /
                službu, které si zákazník objednal a zákazník se zavazuje
                produkt / službu převzít a zaplatit prodávajícímu kupní cenu.
                Povinnosti dodat produkt / službu je prodávající zbaven v
                případě vyprodání zásob, o čemž bude zákazník informován.
              </p>
              <p className='pb-3'>
                <b> 2.6</b> Vlastnické právo k produktům přechází na zákazníka
                zaplacením kupní ceny a jeho převzetím.
              </p>
              <p className='pb-3'>
                <b> 2.7</b> Zákazník je srozuměn s tím, že pro řádné užívání
                online vzdělávacích produktů je třeba z jeho strany splnit
                technické požadavky – aktualizovaný software. V případě použití
                neaktualizovaného softwaru – internetového prohlížeče může být
                omezena funkcionalita.
              </p>
            </section>

            <section id='part3' className='pt-8'>
              {' '}
              <ArticleTitle text='3. Cena a Způsob úhrady' />
              <p className='pb-3'>
                <b>3.1</b> Cena produktů a služeb je uvedena na webových
                stránkách prodávajícího. Cena je vždy uvedena i na prodejním
                formuláři.
              </p>
              <p className='pb-3'>
                <b>3.2</b> Prodávající zákazníkovi vystaví v souvislosti s
                platbami prováděnými na základě smlouvy daňový doklad – fakturu,
                který slouží jako doklad o zakoupení produktu či služby.
              </p>
              <p className='pb-1'>
                <b>ZPŮSOB ÚHRADY</b>
              </p>
              <p className='pb-3'>
                <b>3.3</b> Cenu produktu/služby a případné náklady spojené s
                dodáním hradí zákazník bezhotovostně způsobem zvoleným v
                prodejním formuláři.
              </p>
              <p className='pb-3'>
                <b>3.4</b> Pro bezhotovostní platby jsou platební metody
                napojeny na platební bránu společnosti Stripe, Inc., která
                poskytuje zabezpečenou technologii přijímání platebních karet a
                online bankovních převodů. Čísla platebních karet a hesla k
                elektronickému bankovnictví jsou zadávána pomocí zabezpečeného a
                důvěryhodného kanálu společnosti Stripe, Inc..
              </p>
              <p className=''>
                <b>Využít můžete těchto možností plateb:</b>
              </p>
              <p className=''>1. online platební kartou</p>
              <p className='pb-3'>
                2. rychlým bankovním převodem, pokud je pro vás umožněn.
              </p>
              <p className='pb-3'>
                <b>3.5</b> Zákazník je povinen uhradit cenu společně s uvedením
                správného variabilního symbolu platby, jinak prodávající nebude
                schopen platbu identifikovat a poskytnout včas požadované
                plnění.
              </p>
              <p className='pb-3'>
                <b>3.6</b> Kupní cena je splatná do 7 dnů od uzavření smlouvy
                (od data vystavení faktury), není-li uvedeno jinak. Závazek
                zákazníka uhradit cenu za produkt / službu je splněn okamžikem
                připsání příslušné částky na účet prodávajícího.
              </p>
              <p className='pb-3'>
                <b>3.7</b> V případě prodlení s úhradou jakékoliv částky (kupní
                ceny), kterou je zákazník povinen zaplatit, se zákazník zavazuje
                zaplatit prodávajícímu úrok z prodlení ve výši 0,1% z dlužné
                částky za každý den prodlení, maximálně však do výše kupní ceny
                produktu / služby.
              </p>
            </section>

            <section id='part4' className='pt-8'>
              {' '}
              <ArticleTitle text='4. Dodací podmínky' />
              <p className='pb-3'>
                <b>4.1</b> U online vzdělávacích produktů a programů se dodáním
                rozumí zaslání přístupových údajů ze strany prodejce na
                emailovou adresu zákazníka uvedenou v prodejním formuláři,
                případně zasláním odkazu URL adresy.
              </p>
              <p className='pb-3'>
                <b>4.2</b> Přístupové údaje poskytne prodávající zákazníkovi až
                po zaplacení celé kupní ceny, a to nejpozději do tří dnů,
                není-li uvedeno jinak.
              </p>
            </section>

            <section id='part5' className='pt-8'>
              {' '}
              <ArticleTitle text='5. Zabezpečení a Ochrana autorských práv' />
              <p className='pb-3'>
                <b>5.1</b> Přístupové údaje k online produktům či na příslušnou
                URL adresu jsou určeny pouze pro osobní potřebu zákazníka.
                Zákazník je povinen zachovávat mlčenlivost ohledně informací
                nezbytných k přístupu do jeho uživatelského účtu k online
                produktu či poskytnuté URL adrese. Prodávající nenese žádnou
                odpovědnost za zneužití uživatelského jména a hesla třetí
                osobou.
              </p>
              <p className='pb-3'>
                <b>5.2</b> Produkty, které prodávající prodává (online produkty
                a programy) včetně jejich náplně, podléhají právní ochraně dle
                autorského práva. Jakékoliv jejich šíření nebo poskytování
                třetím osobám bez souhlasu autora je zakázáno. Oprávnění k
                výkonu práva autorské dílo užít může být zákazníkovi poskytnuto
                jen na základě licenční smlouvy. Zákazník odpovídá prodávajícímu
                za újmu, kterou porušením práv na ochranu autorského díla
                způsobí.
              </p>
            </section>

            <section id='part6' className='pt-8'>
              {' '}
              <ArticleTitle text='6. Odstoupení od smlouvy' />
              <p className='pb-3'>
                <b>6.1</b> Odstoupení od smlouvy ze strany spotřebitele
              </p>
              <p className='pb-3'>
                Je-li kupující spotřebitelem, má právo v souladu s ustanovením §
                1829 odst. 1 občanského zákoníku právo odstoupit od smlouvy ve
                lhůtě čtrnácti dnů od převzetí/dodání produktu, zboží, a to bez
                udání důvodu a jakékoliv sankce. Toto právo neslouží jako způsob
                řešení reklamace zboží.{' '}
              </p>
              <p className='pb-3'>
                <b>6.2</b> Rozhodnete-li se pro odstoupení v této lhůtě, prosíme
                vás o dodržení níže uvedených podmínek:
              </p>
              <p className='pb-3'>
                • Nejpozději 14. den po převzetí produktu musí být prodávajícímu
                odeslán projev vůle o odstoupení od smlouvy.
              </p>
              <p className='pb-3'>
                • Vzorový formulář pro odstoupení od smlouvy (vyplňte tento
                formulář a pošlete jej zpět pouze v případě, že chcete odstoupit
                od smlouvy)
              </p>
              <p className='pb-3'>1. Oznámení o odstoupení od smlouvy</p>
              <p className='pb-3'>
                2. Adresát (zde spotřebitel vloží jméno a příjmení, adresu a
                e-mailovou adresu):
              </p>
              <p className='pb-3'>
                3. Oznamuji/oznamujeme (*), že tímto odstupuji/odstupujeme (*)
                od smlouvy o nákupu tohoto zboží (*)
              </p>
              <p className='pb-3'>4. Datum objednání (*)/datum obdržení (*)</p>
              <p className='pb-3'>
                5. Jméno a příjmení spotřebitele/spotřebitelů
              </p>
              <p className='pb-3'>6. Adresa spotřebitele/spotřebitelů</p>
              <p className='pb-3'>
                7. Podpis spotřebitele/spotřebitelů (pouze pokud je tento
                formulář zasílán v listinné podobě)
              </p>
              <p className='pb-3'>8. Datum(*)</p>
              <p className='pb-3'>
                <b>6.3</b> Odstoupení od smlouvy ze strany prodávajícího
              </p>
              <p className='pb-3'>
                Prodávající je oprávněn od kupní smlouvy odstoupit bez
                zbytečného odkladu, pokud zjistí, že druhá strana porušila
                smlouvu podstatným způsobem. Pro účely této smlouvy se za
                podstatné porušení této smlouvy považuje:
              </p>
              <p className='pb-3'>
                • prodlení zákazníka s úhradou kupní ceny nebo dílčí úhrady více
                jak 10 dnů po době splatnosti;
              </p>
              <p className='pb-3'>
                • porušení povinností na ochranu autorského práva (čl. 5 OP) ze
                strany zákazníka.
              </p>
            </section>

            <section id='part7' className='pt-8'>
              {' '}
              <ArticleTitle text='7. Práva z vadného plnění, reklamace' />
              <p className='pb-3'>
                <b>7.1</b> Práva a povinnosti smluvních stran ohledně
                odpovědnosti prodávajícího za vady, tj. práv z vadného plnění,
                se řídí příslušnými obecně závaznými předpisy (zejména
                ustanoveními § 1914 až 1925, § 2099 až 2117 a § 2161 až 2174
                občanského zákoníku).
              </p>
              <p className='pb-3'>
                <b>7.2</b> Prodávající odpovídá zákazníkovi, že zboží při
                převzetí nemá vady. V případě, že věc při převzetí není ve shodě
                s kupní smlouvou, má zákazník právo na to, aby prodávající
                bezplatně a bez zbytečného odkladu produkt uvedl do stavu
                odpovídajícího kupní smlouvě.
              </p>
              <p className='pb-3'>
                <b>7.3</b> Vadné plnění uplatňuje zákazník bez zbytečného
                odkladu u prodávajícího, nejpozději však jednoho měsíce od
                převzetí zboží.
              </p>
              <p className='pb-3'>
                <b>7.4</b> Pro reklamaci nás prosím kontaktujte emailem na
                adrese info-cestasvetla@seznam.cz. Součástí reklamace by mělo
                být přiložení dokladu o koupi a popis závady. O způsobu vyřízení
                reklamace budete informováni emailem.
              </p>
              <p className='pb-3'>
                <b>7.5</b> Zákazník může požadovat bezplatné odstranění vady,
                přiměřenou slevu z ceny a není-li to povaze vady neúměrné
                (zejména nelze-li vadu odstranit bez zbytečného odkladu), lze
                uplatnit požadavek na dodání nové věci bez vad. Není-li oprava
                nebo výměna možná, na základě odstoupení od smlouvy, může
                zákazník požadovat vrácení kupní ceny v plné výši.
              </p>
              <p className='pb-3'>
                <b>7.6</b> Reklamace bude vyřízena bez zbytečného odkladu,
                nejpozději do 30 dnů ode dne uplatnění reklamace, pokud se
                prodávající se zákazníkem nedohodne na delší lhůtě.
              </p>
              <p className='pb-3'>
                <b>7.7</b> Prodávající není povinen nároku zákazníka vyhovět,
                pokud prokáže, že tento před převzetím o vadě zboží věděl nebo
                ji sám způsobil. Prodávající neodpovídá za vady vzniklé v
                důsledku běžného opotřebení nebo nedodržení návodu k použití.
                Rovněž prodávající neodpovídá za závady, které mohou kupujícímu
                vzniknout díky pomalému internetovému připojení kupujícího,
                neaktualizovanému internetovému prohlížeči či nenainstalovanému
                povinnému softwaru pro spuštění online produktu, které není
                schopen ovlivnit. On-line vzdělávací produkty obsahují pouze
                návody a doporučení, prodávající rovněž neodpovídá za úspěch či
                neúspěch zákazníka při jejich aplikaci v praxi.
              </p>
            </section>

            <section id='part8' className='pt-8'>
              {' '}
              <ArticleTitle text='8. Garance vrácení peněz' />
              <p className='pb-3'>
                <b>8.1</b> Protože nám záleží na vaší spokojenosti s našimi
                produkty, poskytujeme vám u vybraných produktů možnost do 14 dnů
                od zakoupení, tj. zaslání přístupového odkazu či URL adresy na
                vámi uvedenou emailovou adresu, odstoupit od smlouvy s garancí
                vrácení peněz pro případ, že s produktem nebudete spokojeni či
                zjistíte, že zakoupený produkt či program není pro vás vhodný.
                Zda je garance u konkrétního produktu poskytována, je uvedeno na
                webových stránkách vámi vybraného produktu.
              </p>
              <p className='pb-3'>
                <b>8.2</b> Odstoupení od smlouvy v případě nespokojenosti
                zašlete elektronickou formou na info-cestasvetla@seznam.cz s
                prohlášením, že od smlouvy odstupujete a s přiložením kopie
                faktury/daňového dokladu a data nákupu.
              </p>
              <p className='pb-3'>
                <b>8.3</b> Peníze vám budou vráceny stejným způsobem, jako byly
                obdrženy, a to do 30 dnů od doručení emailu s odstoupením od
                smlouvy a zaslaného potvrzení o přijetí opravného daňového
                dokladu na naši emailovou adresu. Po obdržení žádosti dojde
                automaticky k deaktivaci vašeho přístupu k produktu.
              </p>
            </section>

            <section id='part9' className='pt-8'>
              {' '}
              <ArticleTitle text='9. Vyloučení odpovědnosti' />
              <p className='pb-3'>
                <b>9.1</b> Všechny poskytované produkty a služby slouží pro
                vzdělávací a informační účely v oblasti osobního rozvoje.
                Informace, které jsou námi zprostředkovávány, jsou pouze návody
                a doporučení. Váš úspěch či neúspěch při jejich aplikaci v praxi
                je čistě ve vaší zodpovědnosti.
              </p>
              <p className='pb-3'>
                <b>9.2</b> V případě vzdělávacích produktů v oblasti osobního
                rozvoje bychom vás rádi upozornili, že v průběhu on-line
                programů můžete být vystaveni fyzickému, psychickému či
                emocionálnímu zatížení, a je plně ve vaší zodpovědnosti a
                rozhodnutí, zda navrhovaná cvičení či úkoly podstoupíte či
                nikoliv.
              </p>
              <p className='pb-3'>
                <b>9.3</b> Rádi bychom vás upozornili, že informace obsažené v
                našich produktech nemohou nahradit individuální konzultaci
                kvalifikovaného odborníka. Rovněž nemohou nahradit lékařskou či
                psychoterapeutickou péči. Produkty mohou obsahovat informace o
                produktech a službách třetích osob a i tyto informace jsou pouze
                doporučením a vyjádřením názoru k dané problematice.
              </p>
            </section>

            <section id='part10' className='pt-8'>
              {' '}
              <ArticleTitle text='10. Závěrečná ustanovení' />
              <p className='pb-3'>
                <b>10.1</b> Tyto obchodní podmínky jsou zobrazeny na webových
                stránkách prodávajícího specifikovaných v bodě 1.3.
              </p>
              <p className='pb-3'>
                <b>10.2</b> Stížnosti a připomínky Spotřebitele, které se týkají
                smluvního vztahu uzavřeného mezi prodávajícím a kupujícím
                vyřizuje prodávající. Stížnosti může Spotřebitel uplatnit na
                adrese info-cestasvetla@seznam.cz. Pokud je stížnost svým
                obsahem reklamací, bude stížnost vyřízena jako reklamace.
              </p>
              <p className='pb-3'>
                <b>10.3</b> Pokud vztah související s užitím webové stránky nebo
                právní vztah založený kupní smlouvou obsahuje mezinárodní
                (zahraniční) prvek, pak strany sjednávají, že vztah se řídí
                českým právem. Tímto nejsou dotčena práva spotřebitele
                vyplývající z obecně závazných právních předpisů.
              </p>
              <p className='pb-3'>
                <b>10.4</b> Prodávající si vyhrazuje právo u vybraných produktů
                upravit další práva a povinnosti smluvních stran nad rámec
                těchto obchodních podmínek. Tato pravidla budou vždy uvedena na
                webových stránkách příslušného produktu a mají přednost před
                ustanoveními OP.
              </p>
              <p className='pb-3'>
                <b>10.5</b> Kupní smlouva včetně obchodních podmínek je
                archivována prodávajícím v elektronické podobě a není veřejně
                přístupná.
              </p>
              <p className='pb-3'>
                <b>10.6</b> Veškerá vaše přání, připomínky, nesrovnalosti a
                situace vzniklé nad rámec tohoto dokumentu budeme řešit s
                maximální snahou vyjít vám vstříc a v odůvodněných případech
                sjednávat nápravu.
              </p>
              <p className='pb-3'>
                <b>10.7</b> Toto aktuální znění obchodních podmínek je platné a
                účinné od 1. listopadu 2024.
              </p>
              <p className='pb-3'>
                <b>10.8</b> V případě změny obchodních podmínek, jsou platné
                obchodní podmínky, které byly v platnosti k datu nákupu.
              </p>
            </section>

            <section id='partfinal' className='pt-8'>
              <p className='pb-3 font-semibold'>
                Děkuji vám za čas, který jste strávili přečtením obchodních
                podmínek. Doufám, že jejich ustanovení předejdou případným
                nedorozuměním a pomohou všechny případně vzniklé záležitosti
                vyřešit ke spokojenosti nás všech.
              </p>
            </section>
          </div>
        </div>
      </section>
      <AuthorSign />
      <section className='min-h-28 bg-amber-900/90'></section>
    </div>
  );
};

export default ContractPage;
