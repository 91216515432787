import React from 'react';
import { NavLink } from 'react-router-dom';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import SocialImageLink from './SocialImageLink';

interface FooterProps {
  homepage?: boolean;
}

const fontStyle =
  "text-xs lg:text-sm text-white font-['MyCustomFont2'] align-text-bottom tracking-tighter hover:shadow-ms transition-all duration-300 ease-in-out font-[600]";

const NewFooter: React.FC<FooterProps> = ({ homepage = false }) => {
  return (
    <footer className='relative -mb-24 bottom-24 h-24 lg:-mb-16 lg:bottom-16 lg:h-16 left-0 w-full  bg-gradient-to-t from-white/60 to-transparent'>
      <div className='flex w-full flex-row justify-between pl-4 lg:pl-10 pt-4 lg:pt-1 items-end'>
        <div className='flex flex-col lg:flex-row lg:space-x-2 pt-1'>
          {!homepage && (
            <>
              <NavLink to={'/gdpr'} className={fontStyle}>
                {'OCHRANA OSOBNÍCH ÚDAJŮ'}
              </NavLink>
              <span className={`hidden lg:flex ${fontStyle}`}>{'|'}</span>
              <NavLink to={'/contract'} className={fontStyle}>
                {'OBCHODNÍ PODMÍNKY'}
              </NavLink>
              <p className="visible lg:hidden text-xs pt-2 lg:text-sm text-white font-['MyCustomFont2'] align-text-bottom tracking-tighter font-[600]">
                © 2024 CESTA SVĚTLA
              </p>
            </>
          )}
        </div>
        {!homepage && (
          <p className="hidden lg:flex left-1/2 transform -translate-x-1/2 text-xs lg:text-sm text-white font-['MyCustomFont2'] font-[600]">
            © 2024 CESTA SVĚTLA
          </p>
        )}
        <div className='flex flex-row z-50 space-x-10 mb-8 lg:mb-4 pr-8 lg:pr-16 justify-end text-white'>
          <SocialImageLink
            image={faEnvelope}
            link={process.env.REACT_APP_MAIL_LINK ?? ''}
          />
          <SocialImageLink
            image={['fab', 'instagram']}
            link={process.env.REACT_APP_IG_LINK ?? ''}
          />
          <SocialImageLink
            pad
            image={['fab', 'facebook']}
            link={process.env.REACT_APP_FB_LINK ?? ''}
          />
        </div>
      </div>
    </footer>
  );
};

export default NewFooter;
