import bgImage from '../util/img/DSC_6049.png';
import AuthorSign from '../components/AuthorSign';
import ArticleTitle from '../components/ArticleTitle';

const GDPRPage: React.FC = () => {
  return (
    <div
      className='top-0 pt-24 w-full min-h-screen bg-cover bg-center bg-fixed flex flex-col justify-center'
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <section className={'p-8 pb-20 bg-custom-test my-auto'}>
        <div className='pl-[20%] pr-[20%] flex flex-row items-center'>
          <div className='flex flex-col'>
            <ArticleTitle text='Orchana osobních údajů a politika Cookies' />
            <p className='pt-6 pb-2'>
              Vážený a milý návštěvníku, těší nás, že jste na našich stránkách.
              <br></br>
              Prosím přečtěte si níže uvedený text o podmínkách užití těchto
              stránek a ochraně Vašich údajů, aby nedošlo k nedorozumění a
              nejasnostem. <br></br> Přejeme si, aby Vám stránky přinášely
              užitek.
            </p>
            <p className='text-lg font-[600] pt-5 pb-2'>
              ■ Ochrana osobních údajů
            </p>
            <p>
              Pokud jste našim zákazníkem, odběratelem novinek nebo návštěvníkem
              webu, svěřujete nám své osobní údaje. Zodpovídáme za jejich
              ochranu a zabezpečení. Seznamte se, prosím, s ochranou osobních
              údajů, zásadami a právy, které máte v souvislosti s GDPR (Nařízení
              o ochraně osobních údajů).
            </p>
            <p className='text-lg font-[600] pt-5 pb-2'>■ Kdo je správce? </p>
            <p>
              Správce: Ing. Veronika Hývlová, IČ 22166432, v evidenci v
              Živnostenském rejstříku ÚMČ Praha 15. Provozuji webovou stránku
              https://cestasvetla.eu. Vaše osobní údaje zpracovávám jako
              správce, tj. určuji, jak budou osobní údaje zpracovávány a za
              jakým účelem, po jak dlouhou dobu a vybírám případné další
              zpracovatele, kteří mi se zpracováním budou pomáhat.
            </p>
            <p className='text-lg font-[600] pt-5 pb-2'>■ Kontaktní údaje</p>
            <p>
              Pokud se na mě budete chtít v průběhu zpracování obrátit, můžete
              mě kontaktovat na e-mail: info-cestasvetla@seznam.cz
            </p>
            <p className='text-lg font-[600] pt-5 pb-2'>■ Prohlašujeme</p>
            <p>
              Prohlašuji, že jako správce Vašich osobních údajů splňuji veškeré
              zákonné povinnosti vyžadované platnou legislativou, zejména
              zákonem o ochraně osobních údajů a GDPR, a tedy že: budu
              zpracovávat Vaše osobní údaje jen na základě platného právního
              důvodu, a to především oprávněného zájmu, plnění smlouvy, zákonné
              povinnosti či uděleného souhlasu, plním dle článku 13 GDPR
              informační povinnost ještě před zahájením zpracování osobních
              údajů, umožním Vám a budu Vás podporovat v uplatňování a plnění
              vašich práv podle zákona o ochraně osobních údajů a GDPR
            </p>
            <p className='text-lg font-[600] pt-5 pb-2'>
              ■ Rozsah osobních údajů a účel zpracování
            </p>
            <p>
              Zpracováváme osobní údaje, které nám svěříte sami, a to z
              následujících důvodů (pro naplnění těchto účelů):
            </p>
            <p className='text-base font-[600] pt-5 pb-2'>
              Poskytování služeb, plnění smlouvy{' '}
            </p>
            <p>
              Vaši e-mailovou adresu nezbytně potřebujeme k zajištění a
              poskytnutí přístupu k našim elektronickým produktům a službám.
              Pokud si objednáte některý z našich elektronických produktů a
              služeb, potřebujeme vaši e-mailovou adresu k účelu plnění smlouvy,
              abychom vám mohli poskytnout službu nebo produkt.
            </p>
            <p className='text-base font-[600] pt-5 pb-2'>Vedení účetnictví</p>
            <p>
              Pokud si objednáte některý produkt či službu z naší nabídky,
              potřebujeme vaše osobní údaje (fakturační údaje), abychom vyhověli
              zákonné povinnosti pro vystavování a evidenci daňových dokladů.{' '}
            </p>
            <p className='text-base font-[600] pt-5 pb-2'>
              Přímý marketing skrz e-mail{' '}
            </p>
            <p>
              Vaše osobní údaje (e-mail a jméno), jaké stránky na našem webu
              navštívíte a na co klikáte v e-mailu, a kdy e-maily nejčastěji
              otevíráte, využíváme za účelem přímého marketingu - zasílání a
              zobrazování obchodních sdělení. Pokud si objednáte některý z
              našich PDF souborů(ať placených nebo zdarma), nebo službu, vnímáme
              oprávněný zájem posílat vám newslettery s tématikou osobního
              růstu. Budeme to dělat maximálně po dobu 5 let od přihlášení.
              Pokud zakoupíte některý z našich produktů či služeb jako váženému
              zákazníkovi vám budeme newsletter s tématikou osobního růstu na
              základě oprávněného zájmu správce zasílat po dobu 5 let. V obou
              případech se můžete ze zasílání newsletterů kdykoliv odhlásit
              tlačítkem "odhlásit se" v patičce každého našeho e-mailu. Vaše
              osobní údaje si ponecháváme po dobu běhu promlčecích lhůt, pokud
              zákon nestanoví delší dobu k jejich uchování nebo jsme v
              konkrétních případech neuvedli jinak.
            </p>

            <p className='text-lg font-[600] pt-5 pb-2'>
              ■ Předávání osobních údajů 3. stranám
            </p>
            <p>
              K vašim osobním údajům mají přístup naši zaměstnanci a
              spolupracovníci, kteří jsou vázáni mlčenlivostí a proškoleni v
              oblasti bezpečnosti zpracování osobních údajů. Většinu
              zpracovatelských operací zvládáme sami a nepotřebujeme k nim 3.
              strany. Pro zajištění některých konkrétních zpracovatelských
              operací, které nedokážeme zajistit vlastními silami, využíváme
              služeb a aplikací zpracovatelů, kteří se na dané zpracování
              specializují a jsou v souladu s GDPR.
            </p>

            <p className='text-base font-[600] pt-5 pb-2'>
              Jsou to poskytovatelé následujících platforem a služeb:
            </p>
            <p className='text-base font-[600] pt-5 pb-2'>
              Poskytovatelé hostingu a serverových služeb
            </p>
            <p>Webglobe – hosting webu</p>

            <p className='text-base font-[600] pt-5 pb-2'>
              Analytické nástroje{' '}
            </p>
            <p>Google Analytics - statistiky návštěvnosti webu</p>

            <p className='text-base font-[600] pt-5 pb-2'>
              Marketing a zákaznická podpora{' '}
            </p>
            <p>MailerLite - nástroj pro hromadnou rozesílku emailů</p>

            <p className='text-base font-[600] pt-5 pb-2'>
              Rezervace, platby a fakturace{' '}
            </p>
            <p>SimplyMeet – rezevace online schůzek</p>
            <p>Stripe - platební brána</p>
            <br></br>
            <p>
              Je možné, že se v budoucnu rozhodneme využít další aplikace či
              zpracovatele, pro usnadnění a zkvalitnění zpracování. Slibujeme
              vám však, že v takovém případě budeme na zpracovatele klást při
              výběru minimálně stejné nároky na zabezpečení a kvalitu zpracování
              jako na sebe.
            </p>
            <p className='text-lg font-[600] pt-5 pb-2'>■ Cookies </p>
            <p>
              Pokud navštívíte náš web a máte zpracování cookies povoleno ve
              svém prohlížeči, vnímáme oprávněný zájem prostřednictvím souborů
              cookies sledovat, jaké stránky na našem webu Pokud si toto
              nepřejete můžete vypnout cookies v prohlížeči. Informace
              shromážděné pomocí cookies nám neumožňují identifikovat vaše
              jméno, kontaktní údaje nebo jiné osobní identifikační údaje, pokud
              se nám je nerozhodnete sami poskytnout. Naše webové stránky lze
              procházet také v režimu, který neumožňuje sbírání osobních údajů.
              Používání cookies můžete na svém počítači zakázat.
            </p>
            {/* Více o tom, jak
              používáme cookies, ale také, jak můžete sami cookies povolit či
              zakázat, si přečtěte v našich Zásadách při práci s cookies. */}
            <p className='text-lg font-[600] pt-5 pb-2'>
              ■ Zabezpečení a ochrana osobních údajů
            </p>
            <p>
              Chráníme osobní údaje v maximální možné míře pomocí moderních
              technologií, které odpovídají stupni technického rozvoje. Chráníme
              je jako kdyby byli naše vlastní. Přijali jsme a udržujeme veškerá
              možná (aktuálně známá) technická a organizační opatření, která
              zamezují zneužití, poškození nebo zničení vašich osobních údajů.
            </p>

            <p className='text-lg font-[600] pt-5 pb-2'>
              ■ Vaše práva v souvislosti ochranou osobních údajů
            </p>
            <p>
              V souvislosti s ochranou osobních údajů máte řadu práv. Pokud
              budete chtít některého z těchto práv využít, prosím, kontaktujte
              nás prostřednictvím e-mailu: info-cestasvetla@seznam.cz Máte právo
              na informace, které je plněno již touto informační stránku se
              zásadami zpracování osobních údajů. Díky právu na přístup nás
              můžete kdykoli vyzvat a my vám doložíme ve lhůtě 90 dní, jaké vaše
              osobní údaje zpracováváme a proč. Pokud se u vás něco změní nebo
              shledáte své osobní údaje neaktuální nebo neúplné, máte právo na
              doplnění a změnu osobních údajů. Právo na omezení zpracování
              můžete využít, pokud se domníváte, že zpracováváme vaše nepřesné
              údaje, domníváte se, že provádíme zpracování nezákonně, ale
              nechcete všechny údaje smazat nebo pokud vznesete námitku proti
              zpracování. Omezit můžete rozsah osobních údajů nebo účelů
              zpracování. (Např. odhlášením z newsletteru omezujete účel
              zpracování pro zasílání obchodních sdělení.)
            </p>
            <p className='text-base font-[600] pt-5 pb-2'>
              Právo na přenositelnost
            </p>
            <p>
              Pokud byste chtěli své osobní údaje vzít a přenést k někomu
              jinému, budeme postupovat stejně jako při využití práva na přístup
              - jen s tím rozdílem, že vám informace dodáme ve strojově čitelné
              podobě. Tady potřebujeme alespoň 30 dní.
            </p>
            <p className='text-base font-[600] pt-5 pb-2'>
              Právo na výmaz (být zapomenut)
            </p>
            <p>
              Vaším dalším právem je právo na výmaz (být zapomenut). Nechceme na
              vás zapomenout, ale pokud si to budete přát, máte na to právo. V
              takovém případě vymažeme veškeré vaše osobní údaje ze systému i ze
              systému všech dílčích zpracovatelů a záloh. Na zajištění práva na
              výmaz potřebujeme 30 dní. V některých případech jsme vázáni
              zákonnou povinností, a např. musíme evidovat vystavené daňové
              doklady po lhůtu stanovenou zákonem. V tomto případě tedy smažeme
              všechny takové osobní údaje, které nejsou vázány jiným zákonem. O
              dokončení výmazu vás budeme informovat na e-mail.
            </p>
            <p className='text-lg font-[600] pt-5 pb-2'>
              ■ Stížnost u Úřadu na ochranu osobních údajů
            </p>
            <p>
              Pokud máte pocit, že s vašimi údaji nezacházíme v souladu se
              zákonem, máte právo se se svou stížností kdykoli obrátit na Úřad
              pro ochranu osobních údajů. Budeme moc rádi pokud nejprve budete o
              tomto podezření informovat nás, abychom s tím mohli něco udělat a
              případné pochybení napravit.
            </p>

            <p className='text-lg font-[600] pt-5 pb-2'>
              ■ Odhlášení ze zasílání newsletterů a obchodních sdělení
            </p>
            <p>
              E-maily s inspirací, články či produkty a službami vám zasíláme
              jste-li náš zákazník na základě našeho oprávněného zájmu. Odhlásit
              se můžete prostřednictvím odhlašovacího odkazu v každém e-mailu.
            </p>
            <p className='text-lg font-[600] pt-5 pb-2'>■ Mlčenlivost</p>
            <p>
              Dovolujeme si Vás ujistit, že naši zaměstnanci, spolupracovníci a
              zpracovatelé, kteří budou zpracovávat Vaše osobní údaje, jsou
              povinni zachovávat mlčenlivost o osobních údajích a o
              bezpečnostních opatřeních, jejichž zveřejnění by ohrozilo
              zabezpečení Vašich osobních údajů. Bez vašeho souhlasu nebudou
              vaše osobní údaje vydány žádné jiné třetí straně. Tyto zásady
              zpracování osobních údajů platí od 18.10.2024. V případě jakékoliv
              změny našich zásad zpracování osobních údajů, bude nové znění
              těchto zásad uvedeno na adrese https://www.cestasvetla.eu/#/gdpr/.
              Podívejte se na tuto stránku v libovolný čas, kdy chcete vidět
              aktuální znění našich zásad zpracování osobních údajů.
            </p>
          </div>
        </div>
      </section>
      <AuthorSign />
      <section className='min-h-28 bg-amber-900/90'></section>
    </div>
  );
};

export default GDPRPage;
