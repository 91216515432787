import React, { useEffect, useRef, useState } from 'react';
import bgImage from '../util/img/DSC_6049.png';
import ArticleTitle from '../components/ArticleTitle';
import StyledButton from '../components/StyledButton';
import { useLocation, useNavigate } from 'react-router-dom';

const discountCodes = ['SLEVA500', 'MIRU35'];

const s60 = '/sezeni60min';
const s90 = '/sezeni90min';
const s60ext = '/sezeni60min5x';
const miru35 = '/miru35';
const prvnisezeni = '/prvnisezeni';

const ReservationPageRev: React.FC = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/articles/prubeh');
  };
  const form = useRef<HTMLFormElement | null>(null);
  const [validDiscount, setValidDiscount] = useState<boolean | null>(null);
  const [urlExtension, setUrlExtension] = useState<string>('');
  const [tempSleva, setTempSleva] = useState<boolean>(false);

  const validateDicsount = (discountCode: string) => {
    const matchFound = discountCodes.some(
      (item) => item.toLowerCase() === discountCode.toLowerCase()
    );
    if (discountCode.toLowerCase() === 'miru35') {
      setTempSleva(true);
    }
    return matchFound;
  };

  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      console.log('hash:', hash);
      if (hash === '#s60') setUrlExtension(s60);
      if (hash === '#s90') setUrlExtension(s90);
      if (hash === '#s60ext') setUrlExtension(s60ext);
      if (hash === '#miru35') setUrlExtension(miru35);
      if (hash === '#prvnisezeni') setUrlExtension(prvnisezeni);
    }
  }, [hash]);

  const formSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validDiscount) {
      form.current?.reset();
      setValidDiscount(null);
    } else {
      if (form.current) {
        const testCode = validateDicsount(form.current?.discount_code.value);
        setValidDiscount(testCode);
        if (!testCode) form.current.reset();
      } else {
        setValidDiscount(null);
      }
    }
  };

  const getPlaceholderText = () => {
    if (validDiscount === null) return 'Zadej slevový kód';
    if (validDiscount) return '';
    if (!validDiscount) return 'Neznámý kód';
  };
  return (
    <div
      className='top-0 pt-24 mb-30 w-full lg:pl-[10%] lg:pr-[10%] bg-cover bg-center bg-fixed flex flex-col items-center justify-center'
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className='flex flex-col w-full pt-8 lg:pt-12 bg-white/90 text-center mb-[10rem] items-center'>
        <ArticleTitle text=' Chci se domluvit na termínu' />
        <div className='flex flex-col w-full bg-custom-test justify-center items-center space-y-4 pb-4 lg:pl-[20%] lg:pr-[20%]'>
          <p className='flex pt-8 pb-4'>
            <StyledButton handleClick={handleClick} text='Jak probíhá sezení' />
          </p>
          <p className='flex '>Slevový kód zadej pod formulářem</p>
        </div>
        <div style={{ width: '100%', height: '100vh' }}>
          {validDiscount && !tempSleva && (
            <iframe
              title='frame3'
              src='https://app.simplymeet.me/cestasvetla/akce-sleva-500?is_widget=1&view=compact'
              style={{ width: '100%', height: '100%', border: 'none' }}
              frameBorder='0'
              scrolling='yes'
            />
          )}
          {validDiscount && tempSleva && (
            <iframe
              title='frame4'
              src='https://app.simplymeet.me/cestasvetla/akce-sleva-miru35?is_widget=1&view=compact'
              style={{ width: '100%', height: '100%', border: 'none' }}
              frameBorder='0'
              scrolling='yes'
            />
          )}
          {!validDiscount && (
            <iframe
              title='frame5'
              src={`https://app.simplymeet.me/cestasvetla${urlExtension}?is_widget=1&view=compact`}
              style={{ width: '100%', height: '100%', border: 'none' }}
              frameBorder='0'
              scrolling='yes'
            />
          )}
        </div>
        <form
          ref={form}
          onSubmit={formSubmit}
          className='flex flex-row bg-white w-full justify-center p-2'
        >
          <div className='flex flex-row'>
            <input
              type='text'
              name='discount_code'
              placeholder={getPlaceholderText()}
              className='block pl-10 pr-10 w-full rounded-xl text-base first-letter:ffirst-line:ocus:outline-none focus:border-transparent'
              required
            />
          </div>
          <div className='ml-6'>
            <StyledButton
              text={validDiscount ? 'Zpět na výběr' : 'Chci slevu'}
              handleClick={() => {}}
            />
          </div>
        </form>
        <h2 className='text-2xl font-bold pb-8 pt-8 text-amber-900'>
          Těším se na tebe!
        </h2>
        {/* <div className="size-64 lg:size-80 overflow-hidden pb-8">
          <img src={img2} alt="Logo" className="h-full w-full object-cover" />
        </div>
        <p className="font-[600] pl-2 pr-2 lg:pl-15 lg:pr-15 pb-4">
          Pro víc informací jak to probíhá a co je dobré vědět o sezení ZDE
        </p> */}
      </div>
    </div>
  );
};

export default ReservationPageRev;
