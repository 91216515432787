import React, { useEffect, useRef, useState } from 'react';
import bgImage from '../util/img/DSC_6049.png';
import { useNavigate } from 'react-router-dom';
import StyledButton from '../components/StyledButton';
import ArticleTitle from '../components/ArticleTitle';
import ImageCarousel from '../components/ImageCarousel';

const images = [
  `/content-data/certification/c1.jpg`,
  `/content-data/certification/c2.jpg`,
  `/content-data/certification/c3.jpg`,
  `/content-data/certification/c4.jpg`,
];

const CertificationPage: React.FC = () => {
  const navigate = useNavigate();

  const handleClickAboutMe = () => {
    navigate('/about');
  };
  const handleClickReservation = () => {
    navigate('/reservation');
  };
  const handleClickTheta = () => {
    navigate('/articles/theta');
  };

  return (
    <>
      <div
        className='flex flex-col top-0 pt-24 w-full min-h-screen bg-cover bg-center bg-fixed justify-center'
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <section className='pl-4 pr-4 lg:pl-[25%] lg:pr-[25%] pt-12 pb-4 bg-custom-test font-[500]'>
          <div className='flex flex-col w-full mt-4 text-center'>
            {/* <ArticleTitle smallHide text="Certifikáty" /> */}

            <p className='pt-2 pb-4 font-[600] leading-[2]'>
              Zajímá tě moje kvalifikace? Tak jsi tu správně!
            </p>
          </div>
        </section>
        <ImageCarousel images={images} key='imCarousel1' />
        {/* <section className='pt-6 pb-16 bg-custom-test font-[500]'>
          <div className='flex flex-col w-full leading-[2] items-center space-y-5'>
            <div className='flex flex-col lg:flex-row lg:space-x-5 justify-center space-y-5 lg:space-y-0'>
              <img src={images[1]} alt='Logo' className='w-full lg:w-[30%]' />
              <img src={images[0]} alt='Logo' className='w-full lg:w-[30%]' />
            </div>
            <div className='flex flex-col lg:flex-row lg:space-x-5 justify-center space-y-5 lg:space-y-0'>
              <img src={images[2]} alt='Logo' className='w-full lg:w-[30%]' />
              <img src={images[3]} alt='Logo' className='w-full lg:w-[30%]' />
            </div>
          </div>
        </section> */}

        <section className='flex flex-col bg-amber-900/90 items-center justify-center space-y-10 pb-40 lg:pb-52 pt-16'>
          <ArticleTitle text='Kam dál?' light />
          <div className='flex flex-col space-y-10 lg:space-y-0 lg:flex-row lg:space-x-10'>
            <div className='flex flex-col size-full md:size-[25rem] bg-white p-10 items-center justify-start text-center'>
              <div className='flex-col justify-between h-full'>
                <ArticleTitle text='O MNĚ' line />
                <p className='text-base font-[500] leading-[1.5]'>
                  Jsem theta healerka, pomáhám překonávat pocit, že se nic
                  nezmění, aby každý mohl najít v duši, těle a mysli klid.
                  Naučila jsem se nevzdávat se a získala tak vnitřní svobodu.
                </p>
                <p className='text-base font-[500] leading-[1.5]'>
                  Ale vždycky to tak nebylo.
                </p>
              </div>
              <div className='relative bottom-0 justify-between  pt-4 pb-4'>
                <StyledButton
                  handleClick={handleClickAboutMe}
                  text='Chci si přečíst vic'
                />
              </div>
            </div>
            {/* <div className='flex flex-col size-full md:size-[25rem] bg-white p-10 items-center justify-start text-center'>
              <div className='flex-col justify-between h-full'>
                <ArticleTitle text='REZERVACE' line />
                <p className='text-base font-[500] leading-[1.5]'>
                  Theta healing je metoda energetického léčení, která tě může
                  posunout blíž k tvým cílům a pomoci zbavit se bloků, které ti
                  brání v úspěchu.
                </p>
              </div>
              <div className='relative bottom-0 justify-between  pt-4 pb-4'>
                <StyledButton
                  handleClick={handleClickReservation}
                  text='Chci se rezervovat'
                />
              </div>
            </div> */}
            <div className='flex flex-col size-full md:size-[25rem] bg-transparent p-10 lg:pt-0 items-start justify-start text-center'>
              <div className='flex flex-col size-full md:size-[20rem] bg-white p-10 pt-0 items-center justify-center text-center'>
                <div className='flex flex-col justify-center items-center h-full'>
                  <ArticleTitle text='REZERVACE' line />
                  <div className='pt-8' />
                  <StyledButton
                    handleClick={handleClickReservation}
                    text='Chci se rezervovat'
                  />
                </div>
              </div>
            </div>
            <div className='flex flex-col size-full md:size-[25rem] bg-white p-10 items-center justify-start text-center'>
              <div className='flex-col justify-between h-full'>
                <ArticleTitle text='METODA THETA HEALING' line />
                <p className='text-base font-[500] leading-[1.5]'>
                  Theta healing je metoda energetického léčení, která tě může
                  posunout blíž k tvým cílům a pomoci zbavit se bloků, které ti
                  brání v úspěchu.
                </p>
              </div>
              <div className='relative bottom-0 justify-between  pt-4 pb-4'>
                <StyledButton
                  handleClick={handleClickTheta}
                  text='Chci si přečíst o theta
        healingu'
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CertificationPage;
