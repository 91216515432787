import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface ReservationItemProps {
  text: string;
  subtext?: string;
  price: string;
  discountPrice?: string;
  leftIcon: IconProp;
  hide?: boolean;
  onClick: (refLink: string) => void;
  refLink: string;
}

const zoomAnimation =
  'transition-all duration-500 ease-in-out transform hover:scale-[1.01] hover:shadow-inner';

const ReservationItem: React.FunctionComponent<ReservationItemProps> = ({
  text,
  subtext,
  price,
  discountPrice,
  leftIcon,
  hide = false,
  onClick,
  refLink,
}) => {
  return (
    <section
      className={`flex flex-col lg:flex-row w-full justify-between p-4 bg-custom-test ${zoomAnimation}`}
      onClick={() => onClick(refLink)}
    >
      <div className='flex flex-col lg:flex-row'>
        <p className='text-black'>
          <FontAwesomeIcon
            className={`relative bottom-0 h-[1.5rem] w-[1.5rem] lg:pr-4`}
            icon={leftIcon}
            size='xl'
          />
        </p>
        <h1 className='font-[600]'>{text}</h1>
      </div>
      <div className='flex flex-col lg:flex-row items-center justify-center text-center pt-3 lg:pt-0'>
        {discountPrice && (
          <p className='line-through lg:pr-3'>{discountPrice}</p>
        )}
        <p className='font-[600]'>{price}</p>
      </div>
    </section>
  );
};

export default ReservationItem;
