export const fetchApiKey = async (service: string) => {
  try {
    const response = await fetch(
      `https://cestasvetla.eu/getApiKey.php?service=${service}`
    );
    const data = await response.json();
    if (data.apiKey) {
      window.alert('loaded:' + data.apiKey);
      return data.apiKey;
    } else {
      throw new Error('API key not found');
    }
  } catch (error) {
    console.error('Error fetching API key:', error);
  }
};
