import React from "react";
import { Link } from "react-router-dom";
import ArticleTitle from "./ArticleTitle";

interface ArticleLinkProps {
  articleId: string;
  title: string;
  summary: string;
  children: React.ReactNode;
}

const ArticleLink: React.FC<ArticleLinkProps> = ({
  articleId,
  title,
  summary,
  children,
}) => {
  return (
    <Link to={`/articles/${articleId}`} className="group ">
      <div className="cursor-pointer flex flex-col items-start bg-white">
        {children} {/* This is where the image and summary will go */}
        <ArticleTitle text={title} />
        <p className="text-gray-700">{summary}</p>
      </div>
    </Link>
  );
};

export default ArticleLink;
