import React, { useEffect, useState } from 'react';
import mammoth from 'mammoth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
const fallbackSrc = '/content-data/feedback/img/default.jpg';

interface FeedbackComponentProps {
  image: string;
  name: string;
  text: string;
  job: string;
  homepage?: boolean;
}

const FeedbackComponent: React.FC<FeedbackComponentProps> = ({
  image,
  name,
  text,
  job,
  homepage = false,
}) => {
  const [textData, setTextData] = useState<string>('');
  const [imgSrc, setImgSrc] = useState<string>(image);

  const handleError = () => {
    setImgSrc(fallbackSrc); // Set fallback image if the original image fails to load
  };

  const handleClick = () => {
    if (text.includes('default') && image.includes('default')) {
      const url = 'https://forms.gle/Z9CCAmkb96KqyXES8';
      // Open the link in a new tab
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  useEffect(() => {
    fetch(text)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        // Convert the binary data to a readable format using Mammoth
        return mammoth.convertToHtml({ arrayBuffer });
      })
      .then((result) => {
        let modifiedHtml = result.value;
        modifiedHtml = modifiedHtml.replace(
          /<img /g,
          '<img class="inline w-4 h-4 mr-1 ml-1" '
        );
        setTextData(modifiedHtml);
      }) // Extract text
      .catch((error) => console.error('Error loading document:', error));
  });

  return (
    <>
      {!homepage && !text.includes('default') && !image.includes('default') && (
        <>
          <div
            className='hidden lg:flex items-start space-x-8 p-4 mb-5 m-3'
            onClick={() => {
              handleClick();
            }}
          >
            {/* Round Image */}
            <img
              src={imgSrc}
              alt={name}
              onError={handleError}
              className='w-24 h-24 rounded-full aspect-square object-cover shadow-lg shadow-amber-900'
            />
            {/* Feedback Text */}
            <div className='pl-1'>
              <h3 className="text-xl font-bold text-amber-900 font-['MyCustomFont2']">
                {name}
              </h3>
              <h6 className="text-sm font-bold text-amber-900/80 font-['MyCustomFont2']">
                {job}
              </h6>

              <div
                className='text-black mt-2 font-[500] text-pretty text-lg'
                dangerouslySetInnerHTML={{ __html: textData }}
              ></div>
            </div>
          </div>

          <div
            className='flex lg:hidden flex-col items-start justify-center p-4 mb-5 lg:m-3'
            onClick={() => {
              handleClick();
            }}
          >
            {/* Round Image */}
            <div
              className='text-black mt-2 font-[500] text-lg'
              dangerouslySetInnerHTML={{ __html: textData }}
            />
            <div className='flex flex-col items-center mt-4 w-full'>
              <img
                src={imgSrc}
                alt={name}
                onError={handleError}
                className='w-24 h-24 rounded-full aspect-square object-cover '
              />

              <div className=''>
                <p className="text-lg font-bold text-amber-900 font-['MyCustomFont2'] text-center">
                  {name}
                </p>
                <p className="text-sm font-bold text-amber-900/80 font-['MyCustomFont2'] text-center">
                  {job}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      {homepage && (
        <div
          className='flex items-start w-1/2 p-4'
          onClick={() => {
            handleClick();
          }}
        >
          {/* Round Image */}

          {/* Feedback Text */}
          <div className='h-full pb-20'>
            {/* <FontAwesomeIcon icon={faQuoteLeft} size="2xl" /> */}
            <div
              className='text-gray-600 font-[500] text-xl h-full -mb-8'
              dangerouslySetInnerHTML={{ __html: textData }}
            ></div>
            <div className='flex flex-row mt-auto space-x-6 items-center'>
              <img
                src={imgSrc}
                alt={name}
                onError={handleError}
                className='w-24 h-24 rounded-full aspect-square object-cover shadow-lg shadow-amber-900'
              />
              <div>
                <h3 className="text-2xl font-bold text-amber-900 font-['MyCustomFont2']">
                  {name}
                </h3>
                <h6 className="text-base font-bold text-amber-900/80 font-['MyCustomFont2']">
                  {job}
                </h6>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackComponent;

//
