import React, { useRef, useState } from "react";

import StyledButton from "./StyledButton";

const Subscribe: React.FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const form = useRef<HTMLFormElement | null>(null);
  const sendEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    if (!form.current) {
      setError("An error occurred while subscribing.");
      setLoading(false);
      return;
    }
    console.log(process.env.REACT_APP_MAILERLITE_API_KEY);
    const apiKey = `Bearer ${process.env.REACT_APP_MAILERLITE_API_KEY}`;
    const emailInput = form?.current?.querySelector(
      'input[name="user_email"]'
    ) as HTMLInputElement;
    const email = emailInput.value;

    const response = await fetch(
      "https://connect.mailerlite.com/api/subscribers",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // prettier-ignore
          "Authorization": apiKey,
        },
        body: JSON.stringify({
          email,
          groups: [`${process.env.REACT_APP_MAILERLITE_NEWSLETTER_GROUPID}`], // Replace with your MailerLite Group ID
        }),
      }
    );

    const data = await response.json();

    if (response.ok) {
      setSuccess(true);
    } else {
      console.log("Subsribe error:", data.message);
      setError("Chyba při přihlašování.");
    }

    setLoading(false);
  };
  return (
    <section className="flex w-full flex-col pt-14 lg:pt-28 pb-40 bg-amber-900/90 items-center ">
      <div className="flex flex-col lg:flex-row pl-3 pr-3 lg:pl-0 lg:pr-0">
        <p className="text-white text-3xl font-600 pt-1 lg:pr-10 pb-8 lg:pb-0  ">
          Přihlas se k odběru novinek, ať ti nic nového neuteče.
        </p>
        {!success && !error && (
          <form ref={form} onSubmit={sendEmail} className="space-y-4">
            <div className="flex flex-row">
              <input
                type="email"
                name="user_email"
                placeholder="Email"
                className="-mr-28 py-3 px-6 block w-full pl-20 pr-32 rounded-xl text-base"
                required
                accept="email"
              />
              <StyledButton
                loading={loading}
                text={"Přihlásit"}
                handleClick={() => {}}
              />
            </div>
          </form>
        )}
        {success && (
          <p className="text-custom-test2 text-3xl font-600 pt-1 pl-10">
            Úspěšně přihlášeno!
          </p>
        )}
        {error && (
          <p className="text-red text-2xl font-600 pt-1 p1-10">{error}</p>
        )}
      </div>
    </section>
  );
};

export default Subscribe;
