import React from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface ImageCarouselProps {
  images: string[];
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className='pt-6 pb-16 bg-custom-test font-[500]'>
      <div className='w-[80%] max-w-screen-md mx-auto '>
        <Slider {...settings}>
          {images.map((img, index) => (
            <div key={index} className='px-2'>
              <img
                src={img}
                alt={`Slide ${index + 1}`}
                className='w-full lg:w-[100%] object-cover'
                style={{ display: 'block' }} // Ensure the image displays correctly
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

// Custom next arrow button
const CustomNextArrow: React.FC = (props: any) => {
  const { onClick } = props;
  return (
    <button
      onClick={onClick}
      className='absolute -right-6 top-0 h-full bg-white text-amber-900 flex items-center justify-center z-10 opacity-75 hover:opacity-100'
      style={{ width: '40px' }}
    >
      <b>&gt;</b>
    </button>
  );
};

// Custom previous arrow button
const CustomPrevArrow: React.FC = (props: any) => {
  const { onClick } = props;
  return (
    <button
      onClick={onClick}
      className='absolute -left-6 top-0 h-full bg-white text-amber-900 flex items-center justify-center z-10 opacity-75 hover:opacity-100'
      style={{ width: '40px' }}
    >
      <b>&lt;</b>
    </button>
  );
};

export default ImageCarousel;
