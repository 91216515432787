import React, { useEffect, useState } from 'react';
import bgImage from '../util/img/DSC_6049.png';
import ArticleTitle from '../components/ArticleTitle';
import ArticleLink from '../components/ArticleLink';
import Subscribe from '../components/Subscribe';
import AuthorSign from '../components/AuthorSign';

const BlogRootPage: React.FC = () => {
  const [articles, setArticles] = useState<any>({});

  useEffect(() => {
    fetch('/content-data/articles/articles.json')
      .then((response) => response.json())
      .then((data) => {
        setArticles(data);
      })
      .catch((error) => console.error('Error loading feedback data:', error));
  }, []);
  return (
    <div
      className='top-0 pt-24 w-full min-h-screen bg-cover bg-center bg-fixed flex flex-col justify-center'
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <section className={'p-8 pb-20 lg:pl-[20%] lg:pr-[20%] bg-custom-test'}>
        <div className='grid grid-cols-1 lg:grid-cols-3 gap-6'>
          {articles?.articles?.map(
            (article: {
              id: string;
              title: string;
              summary: string;
              image: string;
              hide: boolean;
            }) =>
              !article.hide && (
                <div
                  key={article.id}
                  className='p-4 border rounded shadow hover:shadow-lg transition-shadow duration-200 bg-white'
                >
                  <ArticleLink
                    articleId={article.id}
                    title={article.title}
                    summary={article.summary}
                  >
                    {/* Image at the top */}
                    <img
                      src={'/content-data/articles/img/' + article.image}
                      alt={article.title}
                      className='w-full h-48 object-cover mb-4' // Margin-bottom to separate from the header
                    />
                    {/* Summary at the bottom */}
                  </ArticleLink>
                </div>
              )
          )}
        </div>
      </section>
      <AuthorSign />
      <Subscribe />
    </div>
  );
};

export default BlogRootPage;
