import React, { useContext } from "react";
// import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
// import topImage from "../util/img/DSC_5901.jpg"
import logo from "../util/img/logowhite.svg";
import NewNavigationBar from "./NewNavigationBar";
import { useNavigate } from "react-router-dom";
import MobileNavigationBar from "./NavigationBarMobile";
import bgImage from "../util/img/DSC_6049.png";

const NewHeader: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/newhome");
  };

  const context = useContext(AppContext);

  if (!context) {
    throw new Error("AppContext must be used within an AppProvider");
  }

  return (
    <>
      <header
        className="fixed top-0 left-0 w-full h-auto lg:h-24 z-20 bg-cover bg-center flex flex-col items-center"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundAttachment: "fixed",
        }}
      >
        {/* <img
          src={logo}
          alt="Logo"
          className="hidden xl:flex absolute top-5 left-9 w-16 h-16 fill-current text-white-600 bg-white-200/80 rounded-3xl cursor-pointer"
          onClick={handleClick}
        /> */}
        <NewNavigationBar />
        <MobileNavigationBar />
      </header>
    </>
  );
};

export default NewHeader;
