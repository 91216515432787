import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import NavigationLink from './navigation/NavigationLink';

const MobileNavigationBar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('DOMŮ');

  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const linkClicked = (linkString: string) => {
    setSelectedTab(linkString);
    if (isOpen) setIsOpen(false);
  };

  useEffect(() => {
    if (location.pathname.includes('articles')) setSelectedTab('ČLÁNKY');
  }, [location]);

  return (
    <div className='bg-custom-test fixed w-full top-0 z-50 lg:hidden'>
      <div className='flex justify-between items-center p-4 shadow-lg shadow-amber-900'>
        <div className='flex items-center justify-center w-full'>
          <button
            onClick={toggleMenu}
            className='text-amber-900 hover:text-amber-900 transition duration-300 ease-in-out focus:outline-none flex items-center justify-center'
          >
            <FontAwesomeIcon
              className='pl-4 pt-5'
              icon={isOpen ? faTimes : faBars}
              size='xl'
            />
            <span className="text-lg text-amber-900 py-1 px-5 font-extrabold font-['MyCustomFont2']">
              {selectedTab}
            </span>
          </button>
        </div>
      </div>
      {isOpen && (
        <nav className='bg-white/10 shadow-lg shadow-amber-900 transition duration-300 ease-in-out'>
          <ul className='flex flex-col items-center space-y-4 p-4'>
            <NavigationLink
              dark
              navTo={'/'}
              navString={'DOMŮ'}
              navStringCall={linkClicked}
            />
            <NavigationLink
              dark
              navTo={'/about'}
              navString={'O MNĚ'}
              navStringCall={linkClicked}
            />
            <NavigationLink
              dark
              navTo={'/articles'}
              navString={'ČLÁNKY'}
              navStringCall={linkClicked}
            />
            <NavigationLink
              dark
              navTo={'/certification'}
              navString={'CERTIFIKACE'}
              navStringCall={linkClicked}
            />
            <NavigationLink
              dark
              navTo={'/reservation'}
              navString={'REZERVACE'}
              navStringCall={linkClicked}
            />
            <NavigationLink
              dark
              navTo={'/feedback'}
              navString={'RECENZE'}
              navStringCall={linkClicked}
            />
            <NavigationLink
              dark
              navTo={'/contact'}
              navString={'KONTAKT'}
              navStringCall={linkClicked}
            />
          </ul>
        </nav>
      )}
    </div>
  );
};

export default MobileNavigationBar;
